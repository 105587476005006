<template>
  <main class="view-help">
    <Header />

    <section class="background-cover">
      <div class="card">
        <div class="card-header">
          <h1>How to use Pin point</h1>
    
        </div>
        <div class="card-body">
           <img src="@/assets/images/How-to-use-guide.png" />

        </div>
      </div>
    </section>


  </main>
</template>

<script>
import Header from "@/components/Header";
const Stickyfill = require("stickyfilljs");

export default {
  name: "help",
  components: { Header },
  mounted() {
    this.$nextTick(function() {
      var elems = document.querySelectorAll(".scrollspy");
      var instances = M.ScrollSpy.init(elems, {
        scrollOffset: 50
      });
      var element = document.getElementById("sticky");
      Stickyfill.add(element);
    });
  },
  methods: {
    smoothScroll(anchor, duration) {}
  }
};
</script>
